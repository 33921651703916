import { FullImgHero } from "../../../Components"
import RightArrow from "../../../Assets/Images/Partners/rightArrow.svg"
import "./MainContent.css"
import { FC, useContext, useEffect } from "react"
import { CategoryID } from "../../../Context/CategoryID"
import PartnersService from "../../../Services/PartnersService"
import usePost from "../../../Custom Hooks/usePost"
import { observeElements } from "../../../Assets/Js/animated"

type MainProps = {
    id:number
}

const MainContent: FC<MainProps> = ({id}) => {

    useEffect(() => {
        observeElements();
    }, []);

    const {categoryId } = useContext(CategoryID)

    const [handleGet, , data] = usePost(PartnersService.getAll)

    useEffect(() => {
            handleGet({ category_id: id });
    }, []);
    
    const partners = data?.data?.data?.partners?.map((partner: any, index: any) => {
        if (index != 0) {
            return (
                <div key={partner.id} className="partners-main-con">
                    <FullImgHero additionalClass="ma3jon p-0">
                        <div className="partners-img bg-full"
                            style={{
                                backgroundImage: partner.image != null ? `url(${partner.image})` : ""
                            }}
                        ></div>
                        <div className="partners-logo bg-full"
                            style={{
                                backgroundImage: partner.logo != null ? `url(${partner.logo})` : ""
                            }}></div>
                    </FullImgHero>
                    <div className="flex flex-partners fadein">
                        <p>{partner.description}
                        </p>
                        {partner.link != null ? <a href={`${partner.link}`} target="_blank">{partner.link.slice(8)} <span><img src={RightArrow} alt="right-arrow" /></span></a> : <></>}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div key={partner.id} className="partners-main-con">
                    <FullImgHero additionalClass="ma3jon p-0">
                        <div className="partners-img bg-full"
                            style={{
                                backgroundImage: partner.image != null ? `url(${partner.image})` : ""
                            }}
                        ></div>
                        <div className="partners-logo bg-full"
                            style={{
                                backgroundImage: partner.logo != null ? `url(${partner.logo})` : ""
                            }}></div>
                    </FullImgHero>
                    <div className="flex flex-partners fadein">
                        <p>{partner.description}
                        </p>
                        {partner.link != null ? <a href={`${partner.link}`} target="_blank">{partner.link.slice(8)} <span><img src={RightArrow} alt="right-arrow" /></span></a> : <></>}
                    </div>
                </div>
            )
        }

    }
    )
   
    if(id<=3){
        return (

            <div className="col-12 col-md-10 p-0 flex-grow-1" >
                
                {partners}
            </div>
        )
    }else{
        return (

            <div className="col-12 col-md-10 p-0  text-align-center justify-content-center  align-items-center d-flex" >
                <p style={{fontSize:"1.5rem"}}>No partners available for this category.</p>
            </div>
        )
    }
       
    
   
}

export default MainContent