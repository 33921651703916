import { motion } from "framer-motion"
import { TimeLineData } from "../../../Assets/Data"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";
import "./TimeLine.css"

const TimeLine = () => {
    useEffect(() => {
        observeElements();
    }, []);

    const timeLine = TimeLineData.map((item, index) => (
        <div className="date-text-con" key={item.id}>
            <p className="m-0 fadein">
                <span>{item.date}</span>
                {item.title}
            </p>
        </div>
    )
    )
    return (
        <div className="small-time-line">
            <div className="container-fluid time-line-con">
                {timeLine}
            </div >
        </div>
    )
}

export default TimeLine