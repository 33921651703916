import { ListOfDataProps } from "../../../../Models/AboutUS/AboutUsTypes"

const ListOfServicesData: ListOfDataProps[] = [
    {
        id: 1,
        title: "Provide Healthcare Solutions",
        description: "We provide comprehensive healthcare solutions for both private and public sectors, including consulting, technology implementation, health policy advocacy, education, public health initiatives, and wellness programs, with the goal of enhancing patient care and improving health outcomes"
    },
    {
        id: 2,
        title: "Our Divisions",
        description: "We serve a diverse range of sectors, including pharmaceuticals, medical, cosmetic, and aesthetic products. Our services extend to health consultations, safety consultations, medical equipment supply, installation and services, training and support, maintenance and repair, technical support, regulatory compliance, and equipment upgrades"
    }
]
export default ListOfServicesData