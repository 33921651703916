import { Outlet, useLocation } from "react-router-dom"
import { Footer, Header } from "./Sections"
import { useEffect, useState } from "react"
import CategoryIDPropvider from "./Context/CategoryID"
import BackButton from "./Components/BackButton/BackButton"

const Root = () => {
    const [isHome, setIsHome] = useState<boolean>(false)
    const location = useLocation()
    useEffect(() => {
        console.log("created by Muhammad Khalaf / Abdalrahman Alaswad / Reem Georges")
        if (location.pathname == "/") {
            setIsHome(true)
        }
        else {
            setIsHome(false)
        }
    }, [location.pathname])
    return (
        <div className="App">
            <CategoryIDPropvider>
                <Header />
                <Outlet />
                <Footer />
                {/* {isHome == true ? <></> : <Footer />} */}
                <BackButton />
            </CategoryIDPropvider>
        </div>
    )
}

export default Root