import { ListOfGoalsData } from "../../../Assets/Data"
import { motion } from "framer-motion"
import "./ListOfGoals.css"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";

const ListOfGoals = () => {
    useEffect(() => {
        observeElements();
    }, []);

    const listOfGoals = ListOfGoalsData.map(listOfGoals => {
        if (listOfGoals.id == 1 || listOfGoals.id == 3) {
            return (
                <div className="col fade-left" key={listOfGoals.id}>
                    <h2>{listOfGoals.title}</h2>
                    <p>{listOfGoals.description}
                    </p>
                </div>

            )
        }
        else {
            return (
                <div className="col fade-right" key={listOfGoals.id}>
                    <h2>{listOfGoals.title}</h2>
                    <p>{listOfGoals.description}
                    </p>
                </div>

            )
        }

    }
    )
    return (
        <div className="list-of-goals container-fluid">
            <div className="row row-cols-1 row-cols-md-2">
                {listOfGoals}
            </div>
        </div>
    )
}

export default ListOfGoals