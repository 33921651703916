import { createBrowserRouter, RouteObject } from "react-router-dom";
import Root from "../Root";
import { Home, index } from "../Pages";
import AboutRoutes from "./AboutRoutes/AboutRoutes";
import OurServicesRoutes from "./OurServices/OurServicesRoutes";
import PartnersRoutes from "./PartnersRoutes/PartnersRoutes";
import ContactRoutes from "./ContactRoutes/ContactRoutes";
import BlogRoutes from "./BlogRoutes/BlogRoutes";
import SinglePostRoutes from "./SinglePostRoutes/SinglePostRoutes";

// Define the routes
const routes: RouteObject[] = [
    {
        path: "/",
        Component: Root,
        children: [
            {
                index: true,
                Component: Home
            },
            ...AboutRoutes,
            ...OurServicesRoutes,
            ...PartnersRoutes,
            ...ContactRoutes,
            ...BlogRoutes,
            ...SinglePostRoutes

        ]
    },


];

// Create the router
const router = createBrowserRouter(routes);

export default router;