export type SystemsDataProps = {
    id: number,
    logoClassName: string,
    imgClassName: string,
    descriprion: string
}

const SystemsData: SystemsDataProps[] = [
    {
        id: 1,
        logoClassName: "ma3jon-logo-1",
        imgClassName: "ma3jon-img-1",
        descriprion: `We have adopted the German enterprise resource planning (ERP) system, SAP, to ensure all operations and processes are streamlined across procurement, manufacturing, service, sales, finance and HR.
Our partners can have access to reports to assess performance and 
ensure accountability and credibility of our operations.`
    },
    {
        id: 2,
        logoClassName: "ma3jon-logo-2",
        imgClassName: "ma3jon-img-2",
        descriprion: "Our partnership with QuintilesIMS enables us to have access to information, technology and service solutions, enabling us to make more informed decisions and enhancing our performance. Ultimately, our partners receive added value each day. We pride our strong and healthy relations with the leading local banks in supporting our growth year after year. Our success is also based on sound management. We audit our operations and follow local financial and auditing requirements to ensure credibility and accountability."
    }
]
export default SystemsData