import { MisionVisionProps } from "../../../../Models/AboutUS/AboutUsTypes"

const MisionVisionData: MisionVisionProps[] = [
    {
        id: 1,
        title: "Vision",
        description: " To revolutionize healthcare through innovative medical solutions that enhance patient outcomes and empower healthcare professionals, while being a leading provider in the GCC region committed to excellence and accessibility"
    },
    {
        id: 2,
        title: "Mission",
        description: "Our Mission is to provide high-quality healthcare solutions that enhance patient health and well-being through innovative products and services. We are dedicated to fostering partnerships with healthcare professionals and advancing our expertise to meet diverse client needs while positively impacting the healthcare landscape in the region."
    }
]
export default MisionVisionData