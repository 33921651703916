import "./OurCirtificationDescription.css"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";

const OurCirtificationDescription = () => {
    useEffect(() => {
        observeElements();
    }, []);

    return (
        <>
            <div className="our-cirtification-description fadein">
                <p>
                    Boushahri Group Medical was established as an independent legal entity in 2009. The company has spread its roots into all <br />
                    sectors of the medical industry with a signifcant presence in the Pharmaceuticals, Dermatology & Aesthetics sectors.
                </p>
            </div>
        </>
    )
}

export default OurCirtificationDescription