import { ListOfWarehouses } from "../../../../Assets/Data"
import { FullImgHero } from "../../../../Components"
import { useEffect } from 'react';
import { observeElements } from "../../../../Assets/Js/animated";
import "./OurWarehouse.css"

const OurWarehouse = () => {
    useEffect(() => {
        observeElements();
    }, []);
    const listOfWarehouses = ListOfWarehouses.map(item => {
        if (item.id == 1 || item.id == 4) {
            return (
                <div className="col fade-left" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            )
        }
        else if (item.id == 2) {
            return (
                <div className="col fadeout" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            )
        }
        else if (item.id == 5) {
            return (
                <div className="col fadein" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            )
        }
        else if (item.id == 3 || item.id == 6) {
            return (
                <div className="col fade-right" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            )
        }

    }
    )
    return (
        <>
            <FullImgHero additionalClass="our-warehouse-hero" />
            <div className="list-of-warehouses container-fluid">
                <div className="row row-cols-2 row-cols-md-3">
                    {listOfWarehouses}
                </div>
            </div>
        </>
    )
}

export default OurWarehouse