// site.js
export function observeElements() {
    var fadersleft = document.querySelectorAll(".fade-left");
    var fadersright = document.querySelectorAll(".fade-right");
    var faderin = document.querySelectorAll(".fadein");
    var fadecount = document.querySelectorAll(".fadecount");
    var fadeout = document.querySelectorAll(".fadeout");

    var appearOptions = {
        threshold: 0,
        rootMargin: "0px 0px -100px 0px"
    };
    var appearScroll = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (!entry.isIntersecting)
                return;
            entry.target.classList.add("_appear");
            appearScroll.unobserve(entry.target);
        });
    }, appearOptions);

    if (fadersleft) {
        fadersleft.forEach(function (fader) {
            appearScroll.observe(fader);
        });
    }
    if (fadersright) {
        fadersright.forEach(function (fader) {
            appearScroll.observe(fader);
        });
    }
    if (faderin) {
        faderin.forEach(function (fader) {
            appearScroll.observe(fader);
        });
    }
    if (fadeout) {
        fadeout.forEach(function (fader) {
            appearScroll.observe(fader);
        });
    }
    if (fadecount) {
        fadecount.forEach(function (fader) {
            appearScroll.observe(fader);
        });
    }
}
