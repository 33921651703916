import { MisionVisionData } from "../../../Assets/Data"
import "./MisionVision.css"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";

const MisionVision = () => {
    useEffect(() => {
        observeElements();
    }, []); 


    const visionMision = MisionVisionData.map(item => {
        if (item.id == 1) {
            return (
                <div className="col-12 col-md-6 fade-left" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>
                        {item.description}
                    </p>
                </div>
            )
        }
        else {
            return (
                <div className="col-12 col-md-6 fade-right" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>
                        {item.description}
                    </p>
                </div>
            )
        }

    }
    )
    return (
        <div className="container-fluid vision-mision">
            <div className="row">
                {visionMision}
            </div>
        </div>
    )
}

export default MisionVision