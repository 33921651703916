import { ListOfDataProps } from "../../../../Models/AboutUS/AboutUsTypes"



const ListOfGoalsData: ListOfDataProps[] = [
    {
        id: 1,
        title: "Our Ultimate Goals",
        description: "Our primary goal is to provide our community with the best products and services, ensuring the highest standards of quality and care in healthcare solutions."
    },
    {
        id: 2,
        title: "Short Term Goals",
        description: "Our short-term goal is to establish Boushahri Group Medical in the GCC as the first differentiated medical company, setting new standards in healthcare solutions and services."
    },
    {
        id: 3,
        title: "Long Term Goals",
        description: "Our long-term goal is to expand our presence to Middle Eastern countries, enhancing our reach and impact in the healthcare sector across the region."
    },
    {
        id: 4,
        title: "Where We Stand Today",
        description: "We are proud to be recognized as one of the top 10 leaders in the healthcare sector within the Kuwait market, demonstrating our commitment to excellence and innovation in healthcare solutions."
    }
]
export default ListOfGoalsData