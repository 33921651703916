import React, { FC } from "react"
import "./BackButton.css"
import { useEffect,useState } from 'react';



function BackButton() {
    const [BackButton,setBackButton] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY > 100){
                setBackButton(true);
            }else{
                setBackButton(false);
            }
        })
    },[])

    const scrollUp=()=>{
        window.scrollTo({
            top:0,
            behavior:"smooth"
        })
    };

    return (
        <>
        {BackButton && (
            <button className="circle" onClick={scrollUp}>
            <svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154.14 154.14"><g data-name="Layer 1"><circle cx="77.07" cy="77.07" r="77.07" style={{fill:"#b6cbe7" }}/><path style={{fill:"none",stroke:"#fff",strokeMiterlimit:"10",strokeWidth:"10px"}} d="m39.41 95.9 37.66-37.66 37.66 37.66"/></g></svg>
            </button>
        )}
        
        </>
    )
}

export default BackButton