import { ListOfDataProps } from "../../../../Models/AboutUS/AboutUsTypes";

const ListOfWarehouses: ListOfDataProps[] = [
    {
        id: 1,
        title: "900m2",
        description: "A spacious facility designed for optimal storage."
    },
    {
        id: 2,
        title: "ISO Compliant",
        description: "Meets international quality standards"
    },
    {
        id: 3,
        title: "GDP Compliant",
        description: "Adheres to good distribution practices for reliable operations."
    },
    {
        id: 4,
        title: "Chilled Zone",
        description: "A dedicated area for temperature-sensitive products, equipped with modern technology to ensure efficient operations."
    },
    {
        id: 5,
        title: "Full Control & Alarm System",
        description: "Continuous monitoring for temperature and humidity, ensuring safety at all times."
    },
    {
        id: 6,
        title: "Our Fleet",
        description: "Temperature-controlled vehicles that comply with international standards for safe storage and transportation."
    }
]
export default ListOfWarehouses