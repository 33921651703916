import { FullImgHero } from "../../../../Components"
import { Gallery, OurCirtificationDescription } from "../../../../Sections"
import "./OurCertification.css"


const OurCertification = () => {
    return (
        <>
            <FullImgHero additionalClass="our-crtification-hero">
                <div className="text">
                    <h2>Taking Steps towards <br />
                        <span>a Greener Future</span>
                    </h2>
                </div>
            </FullImgHero>
            <OurCirtificationDescription />
            <Gallery />
        </>
    )
}

export default OurCertification