import "./Footer.css"
import logo1 from "../../Assets/Images/Footer/Vector (2).png";
import logo2 from "../../Assets/Images/Footer/Vector (3).png";
import logo3 from "../../Assets/Images/Footer/Vector (4).png";
import usePost from "../../Custom Hooks/usePost";
import SubscribeService from "../../Services/SubscribeService";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SocialData } from "../../Assets/Data";
import { SocialIcon } from "../../Components";
import PartnersService from "../../Services/PartnersService";
import useGet from "../../Custom Hooks/useGet";
import { CategoryID } from "../../Context/CategoryID";

const Footer = () => {
    const { setCategoryId } = useContext(CategoryID)
    const [subscribe, loading] = usePost(SubscribeService.post)
    const [data,] = useGet(PartnersService.getCategory())
    const [email, setEmail] = useState<string>("")
    const navigate = useNavigate()
    const Subscribe = () => {
        subscribe({
            email: email
        })
    }
    const socials = SocialData.map(social => (
        <SocialIcon social={social} />
    ))
    const handleNavigate = (id: any) => {
        setCategoryId(id)
        navigate("/partners")

    }
    const partenersLinks = data?.data?.data?.categoryPartners?.map((link: any) => (
        <a onClick={() => handleNavigate(link.id)} style={{
            cursor: "pointer"
        }}>{link.name}</a>
    ))
    return (
        <div className="container-fluid footer flex-center">
            <div className="container">
                <div className="row row-cols-md-5 row-cols-2">
                    <div className="col">
                        <div className="content-footer">
                            <h4>ABOUT</h4>
                            <Link to="/about-us">Overview</Link>
                            <Link to="/About-Us/our-goals">Our Goals</Link>
                            <Link to="/About-Us/our-certification">Our Certifcation</Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>SERVICES</h4>
                            <Link to="/services">Overview</Link>
                            <Link to="/services/our-warehouse">Medical Warehouse</Link>
                            <Link to="/services/our-systems">Our Systems</Link>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>PARTNERS</h4>
                            <Link to="/partners">Overview</Link>
                            {partenersLinks}
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>CONTACTS</h4>
                            <a href="mailto: info@boushahrigm.com">info@boushahrigm.com</a>
                            <a href="tel: +965 2230111">+965 22390111</a>
                            <a href="tel: +965 2230111">+965 22390222</a>
                            <div className="icons-footer flex">
                                {socials}
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="content-footer">
                            <h4>SUBSCRIBE</h4>
                            <p>to our newsletter</p>
                            <div className="input-footer">
                                <input type="text" onChange={(e) => setEmail(e.target.value)} />

                            </div>
                            <div className="button-footer flex-center">
                                <button onClick={Subscribe}>
                                    {loading == null ? "Subscribe" :
                                        loading == "loading" ? "loading..." :
                                            loading == true ? "Email Has Been send Successfully" :
                                                "Something went Wrong"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <p>© All rights reserved 2024, Boushahri Group Medical</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;