import { ListOfServicesData } from "../../../Assets/Data"
import { motion } from "framer-motion"
import "./ListOfServices.css"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";

const ListOfServices = () => {
    useEffect(() => {
        observeElements();
    }, []);

    const listOfServices = ListOfServicesData.map(item => {
        if (item.id == 1) {
            return (
                <div className="col fade-left" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            )
        }
        else {
            return (
                <div className="col fade-right" key={item.id}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            )
        }

    }
    )
    return (
        <div className="list-of-services container-fluid">
            <div className="row row-cols-1 row-cols-md-2">
                {listOfServices}
            </div>
        </div>
    )
}

export default ListOfServices