import { FC } from "react"
import { FullImgHero } from "../"
import { SystemsDataProps } from "../../Assets/Data/OurSystems/SystemsData"
import { useEffect } from 'react';
import { observeElements } from "../../Assets/Js/animated";

import "./System.css"
type SystemProps = {
    system: SystemsDataProps,
    id: number
}
const System: FC<SystemProps> = ({ system, id }) => {
    useEffect(() => {
        observeElements();
    }, []);
    return (
        <>
            {id == 2 ? <div className="systems container-fluid p-0">
                <FullImgHero additionalClass="ma3jon p-0">
                    <div className={`${system.imgClassName} bg-full`}></div>
                    <div className={`${system.logoClassName} bg-full`}></div>
                </FullImgHero>
                <div className="systems-text fadein">
                    <p>
                        {system.descriprion}
                    </p>

                </div>
            </div> :
                <div
                    className="systems container-fluid p-0">
                    <FullImgHero additionalClass="ma3jon p-0">
                        <div className={`${system.imgClassName} bg-full`}></div>
                        <div className={`${system.logoClassName} bg-full`}></div>
                    </FullImgHero>
                    <div className="systems-text">
                        <p className="fadein">
                            {system.descriprion}
                        </p>

                    </div>
                </div>}
        </>

    )
}

export default System