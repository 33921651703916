import "./Gallery.css"
import Cirtificate from "../../../Assets/Images/AboutUs/OurCertification/cirtificate.png"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";

const Gallery = () => {
    useEffect(() => {
        observeElements();
    }, []);

    return (
        <div className="gallery row row-cols-1 row-cols-md-3">
            <div className="col fadein">
                <img src={Cirtificate} alt="Cirtificate" />
            </div>
            <div className="col fadein">
                <img src={Cirtificate} alt="Cirtificate" />
            </div>
            <div className="col fadein">
                <img src={Cirtificate} alt="Cirtificate" />
            </div>
        </div>
    )
}

export default Gallery