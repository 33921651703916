import "./LogoCon.css"
import logoHome from "../../../Assets/Images/Header/logo-v4.png"
import logo from "../../../Assets/Images/Header/logo-v3.png"
import { FC, useEffect } from "react"
import { LogoConProps } from "../../../Models/Header/HeaderTypes"
import { observeElements } from "../../../Assets/Js/animated"


const LogoCon: FC<LogoConProps> = ({ isHome }) => {
    useEffect(() => {
        observeElements();
    }, []);

    if (isHome === true) {
        return <a href="/" className="logo justify-content-center flex-column d-flex _home" >
                <img  src={logoHome} alt="logo" />
            </a>
    }
    else {
        return <a href="/" className={"logo _row "}>
            <img  src={logo} alt="logo" />
        </a>
    }
}

export default LogoCon