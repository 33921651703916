import React, { FC } from "react"
import "./FullImgHero.css"
import { useEffect } from 'react';
import { observeElements } from "../../Assets/Js/animated";

type FullImgHeroProps = {
    additionalClass: string,
    children?: React.ReactNode
}
const FullImgHero: FC<FullImgHeroProps> = ({ additionalClass, children }) => {
    useEffect(() => {
        observeElements();
    }, []);

    return (
        <>
            <div className={`container-fluid hero bg-full with-children flex-end-start fadein   ${additionalClass}`}>
                {children}
            </div>
        </>
    )
}

export default FullImgHero