
import "./OurNetworkDescription.css"
import { useEffect } from 'react';
import { observeElements } from "../../../Assets/Js/animated";

const OurNetworkDescription = () => {
    useEffect(() => {
        observeElements();
    }, []);
    return (
        <div className="our-network-description container-fluid">
            <p className="fadein">We take pride in our team of professionals and specialists
                who have established a robust external network with leading
                medical and pharmaceutical manufacturers worldwide.
            </p>
            <p className="fadein">Our strength is further enhanced by our ability to maintain
                long-term performance and partnerships since our beginnings
                in the 1970s.
            </p>
            <p className="fadein">In addition to our strong performance, Boushahri Group
                Medical continues to expand its internal network of clients,
                which includes public health institutions, private sector clinics,
                hospitals, and pharmacies.
            </p>
        </div>
    )
}

export default OurNetworkDescription