import "./Partners.css"
import { CategorySideBar, MainContent } from "../../Sections"
import useGet from "../../Custom Hooks/useGet"
import PartnersService from "../../Services/PartnersService"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";


const Partners = () => {
    const [data, loading] = useGet(PartnersService.getCategory())
    const { id } = useParams<{ id: string }>();
    let parsedId = id ? parseInt(id, 10) : 1;

    useEffect(() => {
        if (isNaN(parsedId) || parsedId > 3) {
            parsedId = 1;
        }
    }, [id]);

    return (
        <div className="container-fluid about-us">
            <div className="row m-0 p-0">
                <CategorySideBar data={data} idCategory={parsedId} />
                <MainContent id={parsedId} />
            </div>
        </div>
    )
}

export default Partners