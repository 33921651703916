//home page 
import { fadeLeftAnimation, fadeRightAnimation } from "../../Utils/Motions/Header/HeaderMotions"
import "./Home.css"
import aesthetic from "../../Assets/Images/Home/aesthetic.jpg"
import medicalEquipment from "../../Assets/Images/Home/medical-equipment.jpg"
import pharmaceuticals from "../../Assets/Images/Home/pharmaceuticals.jpg"
import { useEffect } from 'react';
import { observeElements } from "../../Assets/Js/animated";
import { motion } from "framer-motion"
const Home = () => {
    useEffect(() => {
        observeElements();
    }, []);

    return (
        <>
            <div className="animated"></div>
            <div className="container-fluid home bg-full">
                <div className="preloader"></div>
                <motion.p
                    variants={fadeLeftAnimation}
                    initial="initial"
                    animate="animate" 
                    transition={{
                        duration: 2
                    }}
                >Products that matter, <br />
                    <motion.span
                        variants={fadeRightAnimation}
                        initial="initial"
                        animate="animate"
                        style={{
                            display: "inline-block"
                        }}
                        transition={{
                            duration: 2
                        }}
                    >transforming lives</motion.span>
                </motion.p>
                
            </div>
            <section className="container partner fadein">
                <a href="/partners/1">
                    <div className="info">
                        <div className="info-title">
                            <h2>Aesthetic<br />
                                <span>Division</span>
                            </h2>
                            <div className="button">
                                <span>
                                    Know more
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="image">
                        <img src={aesthetic} alt="aesthetic image" />
                    </div>
                </a>
            </section>
            <section className="container partner _gray fadein">
                <a href="/partners/2">
                    <div className="info">
                        <div className="info-title">
                            <h2>
                                Pharmaceuticals<br />
                                <span>
                                  Division
                                </span>
                            </h2> 
                            <div className="button">
                                <span>
                                    Know more
                                </span>
                            </div>
                        
                        </div>
                    </div>
                    <div className="image">
                        <img src={pharmaceuticals} alt="aesthetic image" />
                    </div>
                </a>
            </section>
            <section className="container partner fadein">
                <a href="/partners/3">
                    <div className="info">
                        <div className="info-title">
                            <h2>
                                 Medical Equipment<br />
                                <span>
                                    Division
                                </span>
                            </h2>
                            <div className="button">
                                <span>
                                    Know more
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="image">
                        <img src={medicalEquipment} alt="aesthetic image" />
                    </div>
                </a>
            </section>
        </>
    )   
}

export default Home